<template>
	<section id="project" class="porfolio-breadcrumb">
		<b-row style="justify-content: space-between" class="mb-2 align-center">
		    <b-col lg="7">
				<Breadcrumb :labels="labels" />
		    </b-col>
		    <b-col lg="5">
		    	<div class="icon-search-wrapper ml-auto" style="max-width: 346px; width: 100%;">
			      	<b-form-group class="mb-0">
				        <b-input-group class="input-group-merge">
					        <b-input-group-prepend is-text>
					            <feather-icon icon="SearchIcon" size="17" />
					        </b-input-group-prepend>
					        <b-form-input
					            placeholder="Search"
					            @input="searchProject"
					        />
				        </b-input-group>
			      	</b-form-group>
			    </div>
		    </b-col>
		</b-row>
		<b-row class="w-15-percent-cl-1 w-20-percent-cl-4 w-15-percent-cl-5 w-15-percent-cl-6">
		    <b-col md="12">  
		    	<div class="btn-action text-right mb-1">

		            <b-button type="button" variant="primary" class="px-1 mr-10 py-1 h-auto size-18 btn-disabled-gray" disabled>Export Projects</b-button>

		            <b-button type="button" variant="primary" class="py-1 h-auto size-18 btn-disabled-gray" disabled>Add Project</b-button>

		        </div>
		    	<b-tabs card class="caption-add min-120" v-model="tabIndex">   
		            <b-tab title="Active" class="active-tab table-custom">
		                <div class="content-tab info-content">
		                    <b-table small :fields="fields_active" :items="active" responsive="lg" tbody-tr-class="size-14 table-row" id="active-table" @head-clicked="headClicked" show-empty class="mb-3 cursor-pointer" @row-clicked="single_detail">
						    	<h2>Projects Overview</h2>
						    	<template #head(name)="data">
							        <span>{{ data.label }}</span>
							        <span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc'}]" style="position: relative; left: 30px;">
							        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
							        </span>
							    </template>
							    <template #head()="data">
									<span class="d-block text-center">{{ data.label }}</span>
								</template>
								<template #cell(name)="data">
									<span class="text-left maxThreeRow">{{ data.item.name }}</span>
								</template>			
								<template #cell(client_name)="data">
									<span class="text-center maxThreeRow">{{ data.item.client_name }}</span>
								</template>
								<template #cell(status)="data">
									<span class="d-block text-center">
										<span v-if="data.item.status == 0">Active</span>
										<span v-else-if="data.item.status == 1">Completed</span>
										<span v-else="data.item.status == 2">Developing</span>
									</span>
								</template>
								<template #cell(commencement_date)="data">
									<span class="d-block text-center">{{ data.item.commencement_date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
								</template>
								<template #cell(completion_date)="data">
									<span class="d-block text-center">{{ data.item.completion_date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
								</template>
								<template #cell()="data">
									<span class="d-block text-center">{{ data.value }}</span>
								</template>
							    <template #empty="scope">
									<template v-if="search">
										<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data found</h4>
									</template>
									<template v-else>
										<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
									</template>
							    </template>

							</b-table>
							<div v-if="this.total > 0" class="paging-cus relative">
								<b-pagination
							      	v-model="currentPage"
							      	:total-rows="total"
							      	:per-page="perPage"
							      	aria-controls="active-table"
							      	first-number
						        	last-number
						        	class="just-center"
							    ></b-pagination>
							    <div class="limit-per">
									<select v-model="selected" class="cursor-pointer">
									    <option v-for="limit in limitpage">{{ limit }}</option>
									</select>			  
							    </div>
							</div>
		                </div>
		            </b-tab>         
		        </b-tabs>   
		    </b-col>
		</b-row>
	</section>
</template>
<script>
	import Breadcrumb from '@/views/itees/admin-dashboard/partial/Breadcrumb'

	export default {
	  	components: {
			Breadcrumb,
		},
	  	data() {
		    return {
		    	activity: {},
		    	labels: { "our-portfolio-project": "" },
		    	search: null,
		    	timer: null,
		    	tabIndex: 0,

		    	fields_active: [
                	{ key: 'name', label: 'Project Name' },
			        { key: 'country_name', label: 'Country' },			        
			        { key: 'status', label: 'Status' },
			        { key: 'client_name', label: 'Client' },
			        { key: 'user_name', label: 'Project Owner' },
			        { key: 'commencement_date', label: 'Start Date' },
			        { key: 'completion_date', label: 'End Date' },
			    ],
		    	active: [],	
		    	sort: {
			    	field: 'id',
			    	type: 'desc'
			    },
			    perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10'
		    }
		},
		mounted () {
		    this.selected = '10'
		},
		watch: {
			search(val) {
            	this.fetchProjectActivityList(val)
            }, 
			currentPage(val){
				this.fetchProjectActivityList(this.search)
			},
			selected(val){
				if(val){
					this.perPage = val
					this.fetchProjectActivityList(this.search)
				}				
			},
		},
		created() {
			this.fetchProjectActivityList(this.search)
		},
		methods: {
			searchProject(val) {
				if (this.timer) {
			        clearTimeout(this.timer);
			        this.timer = null;			        
			    }
			    this.search = val
			},
			fetchProjectActivityList(search){
				this.$store.dispatch('project/ProjectListExpert', {
					limit: this.perPage,
					page: this.currentPage,
					sort: this.sort,
					search: search,
					filter: { "list_search": [{ "field": "search","keyword": search }]}
				})
				.then(response => {
					this.active = response.data.data.data
					this.total = response.data.data.total
					// console.log('active: ', this.active)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			headClicked(key) {
				if(this.sort.field == key) {
					if(this.sort.type == 'asc') this.sort.type = 'desc'
					else this.sort.type = 'asc'
				} else {					
					this.sort.field = key
					this.sort.type = 'desc'
				}
				this.fetchProjectActivityList(this.search)
			},
			single_detail(item) {
		    	this.$router.push(`/expert/our-portfolio/project/${item.country_id}/edit/${item.id}`)
		  	},
		},
	};	

</script>

<style lang="scss">	
	@import "@/assets/scss/_ite_tab_table.scss";
</style>

